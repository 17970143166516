var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('ERow',[_c('ECol',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ReportTable',{attrs:{"name":"progress photos logs","sort-by":"insertedAt","sort-desc":false,"item-key":"id","headers":_vm.headers,"provider":_vm.provider,"filter-fields":_vm.filterFields},scopedSlots:_vm._u([{key:"item.insertedAt",fn:function({ item }){return [_vm._v("\n          "+_vm._s(_vm.formatDate(item.insertedAt))+"\n        ")]}},{key:"item.recipients",fn:function({ item }){return [_c('EReadMore',{attrs:{"content":item.recipients,"max-length":70}})]}},{key:"item.cameraIds",fn:function({ item }){return [_c('EReadMore',{attrs:{"content":item.cameraIds,"max-length":70}})]}},{key:"item.failedCameraIds",fn:function({ item }){return [_c('EReadMore',{attrs:{"content":item.failedCameraIds,"max-length":70}})]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","x-small":""},on:{"click":function($event){return _vm.openProgressPhoto(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" far fa-envelope-open ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("View")])])]}}])}),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"1000"},on:{"click:outside":() => {
            _vm.progressPhotoDialog = false
            _vm.progressPhoto = null
          }},model:{value:(_vm.progressPhotoDialog),callback:function ($$v) {_vm.progressPhotoDialog=$$v},expression:"progressPhotoDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Progress Photo ")]),_vm._v(" "),(_vm.loadProgressPhoto)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.progressPhoto)}}),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":() => {
                  _vm.progressPhotoDialog = false
                  _vm.progressPhoto = null
                }}},[_vm._v("\n              Close\n            ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }