export default (self) => ({
  insertedAt: {
    name: "insertedAt",
    component: "DatePickerRangeSearchFilter",
    attributes: {
      datePickerAttrs: {
        value: [
          self.$moment(new Date()).subtract({ days: 7 }).format("YYYY-MM-DD"),
          self.$moment(new Date()).format("YYYY-MM-DD"),
        ],
      },
    },
  },
  recipients: {
    component: "TextFieldSearchFilter",
  },
  cameraIds: {
    component: "TextFieldSearchFilter",
    name: "cameraId",
  },
  failedCameraIds: {
    component: "TextFieldSearchFilter",
    name: "failedCameraId",
  },
  subject: {
    component: "TextFieldSearchFilter",
  },
})
