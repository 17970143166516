export default (self) => {
  return [
    {
      value: "actions",
      sortable: false,
      align: "center",
      width: 25,
      filterable: false,
    },
    {
      value: "insertedAt",
      text: "Created At",
      width: 150,
      sortable: true,
      filterable: true,
    },
    {
      value: "recipients",
      text: "Recipients",
      width: 175,
      sortable: true,
      filterable: true,
      filter: (value) =>
        !self.recipients || value?.toLowerCase().includes(self.recipients),
    },
    {
      value: "cameraIds",
      text: "Camera IDs",
      width: 175,
      sortable: false,
      filterable: true,
    },
    {
      value: "failedCameraIds",
      text: "Camera IDS (Failed)",
      width: 175,
      sortable: false,
      filterable: true,
    },
    {
      value: "subject",
      text: "Subject",
      width: 175,
      sortable: false,
      filterable: true,
    },
  ]
}
